import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/views/HomePage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/wedding-party",
    name: "wedding-party",
    component: function () {
      return import(/* webpackChunkName: "rsvp" */ "@/views/WeddingParty.vue");
    },
  },
  {
    path: "/policy",
    name: "policy",
    component: function () {
      return import(
        /* webpackChunkName: "policy" */ "@/views/PrivacyPolicy.vue"
      );
    },
  },
  // {
  //   path: "/rsvp",
  //   name: "rsvp",
  //   component: function () {
  //     return import(/* webpackChunkName: "rsvp" */ "@/views/RSVPPage.vue");
  //   },
  // },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

export default router;
