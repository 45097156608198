<template>
  <div class="relative w-full overflow-hidden">
    <div class="flex items-center justify-start animate-scroll">
      <div
        v-for="slide in images.concat(images)"
        :key="slide + Math.random()"
        class="flex-none"
      >
        <img
          :src="`https://appily-ever-after.s3.us-east-2.amazonaws.com/${slide}.jpg`"
          width="300"
          height="300"
          alt="Wedding Placeholder"
          class="mx-auto shadow-lg"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const images = ref([
  "2f606404-07f2-4d59-8348-414a7edb81ea",
  "3edac6eb-a502-4bd9-a43e-f1c1d2e3b967",
  "8f2dd770-b5f8-457a-b50b-069476faa541",
  "9e4d13de-42f4-4b10-8263-1c841f367fe2",
  "12beef8a-df3d-438e-bacb-8a4ed5356828",
  "31d40a16-41e9-4cd8-8683-de2b3488bc21",
  "8959fc8c-0d61-43c0-8769-27ef0ce2333f",
  "74620d03-ec50-4fa7-badf-daa97934f388",
  "952570d2-f78c-48b8-a398-c6fc9c7f3466",
  "ac1e891f-c236-4bf1-8c2e-1a5d9b157f4c",
  "c81b6b7d-f1d5-4f5a-949e-117cbb2e9dcc",
  "ca6f11ca-113c-4143-8279-70ee8e3d901a",
  "cecbea45-2037-4ba9-9d73-b3dbc2fcee53",
  "e4c4c99e-cbcb-4f5f-a6a6-673a1f672eba",
  "e07f9802-02c4-4cf3-b6e8-5cc504722344",
  "ef015e0b-68ac-4fa8-b4b3-ae1b968134ac",
  "f9ac8f70-442b-4db2-a27f-9f88c7a4dc48",
  "fdf17699-76dc-404d-8e20-d3426f885a3b",
  "ff24f41b-74d0-4542-86d9-79c4e2b5d6db",
]);
</script>

<style scoped>
@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-scroll {
  display: flex;
  width: max-content;
  animation: scroll 120s linear infinite;
}
</style>
