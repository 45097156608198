<template>
  <section class="bg-gray-100 py-10 px-6 md:px-12 lg:px-24">
    <h3 class="text-center text-3xl font-bold mb-8 text-gray-800">Our Story</h3>
    <div class="max-w-3xl mx-auto">
      <p class="text-gray-700 leading-relaxed mb-6">
        Once upon a time in a land far far away (aka: Austin, Texas), where the
        tacos are plentiful and the live music never stops, a fateful meeting
        was destined to happen. Kelsey, an OBGYN from Raleigh, NC, had just
        returned from a life-changing medical mission trip to Kenya. Exhausted
        yet fulfilled, she was ready to get back to the grind of residency and
        decided to go to the coffee shop near clinic to finish some work (she
        was trying to like coffee). Meanwhile, Drew, a computer engineer who had
        migrated from chilly Rochester, MN, was busy debugging the meaning of
        life over a cold brew at the very same spot, with his loyal Nova Scotia
        Duck Tolling Retriever, Bruce.
      </p>
      <p class="text-gray-700 leading-relaxed mb-6">
        Kelsey, who absolutely despises the cold, was immediately drawn to
        Bruce’s warm, fluffy presence and couldn’t resist introducing herself to
        his owner, who seemed to be intensely focused on his laptop. Drew, deep
        in thought over why his code was breaking (spoiler: it was a missing
        semicolon), didn’t notice Kelsey at first. That is, until she walked
        over and casually asked, “What kind of dog is that?”
      </p>
      <p class="text-gray-700 italic leading-relaxed mb-6">
        (^Not exactly how the story went, but hey, it sounds cute! Thanks
        Hinge!!)
      </p>
      <p class="text-gray-700 leading-relaxed mb-6">
        After their first date, Drew was hopeful that he’d found the missing
        semicolon of his life. Kelsey learned that Drew wasn’t just a tech
        wizard; he was also a former ski patroller who once handled a medical
        emergency on a plane. Kelsey, fresh from her experiences in Kenya,
        couldn’t help but be impressed. Here was a guy who not only knew his way
        around a computer but also knew how to keep calm in a crisis—whether it
        was on a snowy mountain or at 30,000 feet. Most charming of all, he told
        her about volunteering in the NICU to hold the preemie babies and Kelsey
        was smitten!
      </p>
      <p class="text-gray-700 leading-relaxed mb-6">
        As they continued talking, they discovered their surprising similarities
        and hilarious differences. Kelsey loved the warm Texas sun and would
        shiver at the mere mention of cold weather. She lives for dessert and
        all things baked goods! Drew, on the other hand, was already plotting
        their future move to Michigan, dreaming of snowy winters and cozy nights
        by the fire with Bruce. And he definitely was not going to eat Kelsey’s
        baked goods! Despite the clash, the two quickly bonded over their love
        for Austin’s food scene, their mutual admiration for Bruce’s puppy eyes,
        and their shared belief that life is better in pairs… and realized they
        agreed on the scarier first date topics of politics and religion. Yes,
        they went there on the first date! They meant business!
      </p>
      <p class="text-gray-700 leading-relaxed mb-6">
        Fast forward a few dates, and the two were together whenever they could
        be. A month later, Drew got down on one knee with a Drew-grilled chicken
        wing asking Kelsey to be his girlfriend. Wings are Kelsey’s favorite!
        Kelsey knew she wanted more of Drew’s nerdy humor, his incredible
        stories from his Michigan days, and his way of fixing her computer
        problems. Drew was equally hooked, not just by Kelsey’s intelligence and
        wit, but by the fact that she could talk about the miracle of childbirth
        one minute and binge-watch Love Is Blind with him the next. Plus, she
        lessened her disdain for dog hair and loved that Bruce would snuggle
        with her on the couch.
      </p>
      <p class="text-gray-700 leading-relaxed mb-6">
        They both thought “this could be the one” when they survived a 36 hour
        road trip from Austin to Canada after two months of dating! This was
        Kelsey’s first summer to Drew’s family’s Canadian cottage on Lake
        Muskoka, a place that’s been in the family for more than 100 years!
      </p>
      <p class="text-gray-700 leading-relaxed mb-6">
        Now, after countless tacos, tech talks, and late-night ER stories,
        Kelsey and Drew realized that each good day after good day after good
        day could be the new forever. They found comfort in one another and
        solace in their faith. Sunday mornings when Kelsey wasn’t working, they
        enjoyed church together - and thanks to pecan praline coffee of Grace
        Covenant Church, Kelsey can now say she likes coffee! They’ve tested the
        times through Kelsey’s stressful residency season and came out on top.
        Kelsey is convinced she couldn’t have gotten through the hardest parts
        without him.
      </p>
      <p class="text-gray-700 leading-relaxed mb-6">
        The following summer on Lake Muskoka at his family’s cottage, Drew got
        down on one knee… again. This time with a beautiful ring in place of a
        chicken wing. It was dreamy with just the two of them and the sunset
        behind them.
      </p>
      <p class="text-gray-700 leading-relaxed">
        So here they are, ready to say “I do,” and as they look forward to a
        future filled with love, laughter, and lots of grilled dinners in the
        backyard, they invite you to join them in celebrating their love story.
        Because, let’s be honest, when a doctor from Raleigh meets a techie from
        Rochester in the heart of Texas, with a dog named Bruce in tow, you know
        it’s going to be one heck of a ride.
      </p>
    </div>
  </section>
</template>

<script setup></script>
